import {
  // CheckCircle,
  CreditScore,
  InvertColors,
  LocalPhone,
  LocationOn,
  Mail,
} from "@mui/icons-material";
import {
  Screen1Img,
  Screen2Img,
  Screen3Img,
  Screen4Img,
  Screen5Img,
  Screen6Img,
  Screen7Img,
  Screen8Img,
  Screen9Img,
  Screen10Img,
} from "../assets/img/safaris/SafarisImages";
import {
  BrandSVG,
  DesignSVG,
  HelpSVG,
  MissionSVG, 
  UsersSVG,
} from "../assets/svg/Svgs";
import { Tag } from "antd";

// WHY OPTIONS
export const whyData = [
  {
    Icon: InvertColors,
    title: "Intellectual Stimulation",
    desc:
      "PAUDC offers a unique platform for engaging in intellectually stimulating debates on pressing issues facing Africa today, fostering critical thinking and deepening understanding.",
  },
  {
    Icon: InvertColors,
    title: "Networking Opportunities",
    desc:
      "Attendees have the chance to connect with like-minded individuals from diverse backgrounds, fostering valuable networks that extend beyond the tournament.",
  },
  {
    Icon: InvertColors,
    title: "Cultural Exchange",
    desc:
      "PAUDC celebrates the rich diversity of African cultures, providing a space for participants to learn from each other's traditions, languages, and perspectives.",
  },
  {
    Icon: InvertColors,
    title: "Skill Development",
    desc:
      "Participating in debates at PAUDC hones essential skills such as public speaking, research, teamwork, and negotiation, equipping attendees for success in academia and beyond.",
  },
  {
    Icon: InvertColors,
    title: "Impactful Discussions",
    desc:
      "PAUDC serves as a forum for discussing and addressing key issues facing the African continent, empowering participants to contribute meaningfully to positive change and development.",
  }
];

// FAQS
export const faqData = [
  {
    label: "What is PAUDC 2024?",
    children:
      "PAUDC 2024 refers to the Pan African Universities Debating Championship scheduled to take place in Kampala, Uganda from the 15th to 22nd December 2024. It is the biggest intercollegiate debate championship in Africa.",
  },
  {
    label: "What is the theme of PAUDC 2024?",
    children:
      "The theme of PAUDC 2024 is 'Science, Technology, and Innovation as a tool for Peace building in African countries.'",
  },
  {
    label: "Who can participate in PAUDC 2024?",
    children:
      "PAUDC 2024 is open to university students from across Africa. Participating universities will send teams to compete in the debates.",
  },
  {
    label: "How many countries are expected to participate in PAUDC 2024?",
    children:
      "PAUDC 2024 is expected to have universities from all African countries, with over 650 participants competing in the championship.",
  },
  {
    label: "What is the format of the debates in PAUDC 2024?",
    children:
      "The debates in PAUDC 2024 will follow the British Parliamentary style, involving four teams of two people each in each debate round.",
  },
  {
    label: "Will there be training workshops and interactive forums at PAUDC 2024?",
    children:
      "Yes, in addition to the debates, PAUDC 2024 will include formal training workshops and interactive forums with policy makers, youth leaders, ministers, members of parliament, civil society, and government.",
  },
];


// PLANS
export const plansData = [
  {
    title: "Debaters & Institutional Judges",
    tag: "$220",
    desc: "Experience the essence of African debate culture with our Debaters & Institutional Judges Package. Dive into spirited debates, enriching workshops, and vibrant cultural experiences while enjoying comfortable accommodations and savory meals. Join fellow debaters and judges from across Africa in this memorable journey of intellectual exchange and camaraderie.",
    button: "Book Now - $220",
    features: [
      "Accommodation: Comfortable lodging in a centrally located hotel for the duration of the tournament.",
      "Meals: Daily breakfast, lunch, and dinner, showcasing delicious Ugandan cuisine.",
      "Transportation: Shuttle service to and from the venue, ensuring convenience and accessibility.",
      "Tournament Access: Full access to all debate rounds, workshops, and networking events.",
      "Participant Kit: Exclusive tournament merchandise and essential materials for debaters and judges.",
      "Support Services: Dedicated assistance from our team throughout the event to ensure a seamless experience.",
      "Cultural Experience: Optional excursions to explore the beauty and culture of Uganda at discounted rates."

    ],
  },
  {
    title: "Observers",
    desc: "Witness the brilliance of African debate unfold with our Observers Package. Delve into captivating debate rounds, inspiring speeches, and cultural showcases while relishing delectable meals and cozy accommodations. Connect with participants and fellow observers, immersing yourself in the dynamic atmosphere of the tournament and the rich diversity of African perspectives.",
    button: "Book Now - $300",
    tag: "$300",
    features: [
      "Accommodation: Stay in a reputable hotel within close proximity to the tournament venue.",
      "Meals: Enjoy daily breakfast, lunch, and dinner featuring a variety of local and international cuisines.",
      "Tournament Access: Attend debate rounds, keynote speeches, and cultural showcases as an observer.",
      "Networking Opportunities: Connect with participants, organizers, and fellow observers during networking sessions.",
      "Participant Kit: Receive a tournament souvenir and access to tournament materials.",
      "Optional Activities: Participate in select tournament activities and social events.",
      "Exclusive Discounts: Enjoy special rates for optional excursions and tours around Uganda."
    ],
  },
  {
    title: "Officials",
    desc: "Indulge in the ultimate debating experience with our Officials Package. Enjoy luxurious accommodations, gourmet dining, and VIP treatment throughout your stay. Engage in high-level discussions, networking opportunities, and exclusive excursions, surrounded by esteemed members of the debating community. Immerse yourself in Uganda's vibrant culture and hospitality, creating cherished memories in the Pearl of Africa.",
    button: "Book Now - $500",
    tag: "$500",
    features: [
      "Accommodation: Stay in premium accommodations with additional amenities for a comfortable stay.",
      "Meals: Indulge in daily gourmet meals, including buffet-style breakfasts, lunches, and dinners.",
      "VIP Treatment: Access to exclusive lounges and designated areas for officials.",
      "Tournament Privileges: Priority seating at all debate rounds and special recognition during the event.",
      "Personal Assistance: Dedicated staff available to address any requests or inquiries throughout the tournament.",
      "Networking Opportunities: Engage with high-level stakeholders and decision-makers in the debating community.",
      "Cultural Immersion: Enjoy curated cultural experiences and guided tours to explore the beauty of Uganda."
    ],
  },
];

// TEAM MEMBERS
const teamModel = (name, title, image) => {
  return { name: name, title: title, image: image };
};

export const volunteers = [
  teamModel("Kyambogo University", "Host", Screen1Img),
  teamModel("Olympia Debate Society", "Main Convenor", Screen2Img),
  teamModel("PAUDC Council", "Partner", Screen4Img),
  teamModel("Uganda National Students Association", "Co-Convenor", Screen5Img),
  teamModel("Open Space", "Co-Convenor", Screen6Img),
];

export const organizers = [
  teamModel("Kyambogo University", "Host", Screen1Img),
  teamModel("Olympia Debate Society", "Main Convenor", Screen2Img),
  teamModel("PAUDC Council", "Partner", Screen4Img),
  teamModel("Uganda National Students Association", "Co-Convenor", Screen5Img),
  teamModel("Open Space", "Co-Convenor", Screen6Img),
];

export const partners = [
  teamModel("Kyambogo University", "Host", Screen1Img),
  teamModel("Olympia Debate Society", "Main Convenor", Screen2Img),
  teamModel("PAUDC Council", "Partner", Screen4Img),
  teamModel("Uganda National Students Association", "Co-Convenor", Screen5Img),
  teamModel("Open Space", "Co-Convenor", Screen6Img),
];

// objectives
export const objectivesData = [
  {
    title: "Vibrant Debate Culture",
    desc: "Immerse yourself in Africa's largest continental debating tournament and engage with the continent's brightest minds. PAUDC offers a platform for intellectual exchange, critical thinking, and meaningful dialogue on pressing issues facing Africa and the world.",
    Icon: MissionSVG,
  },
  {
    title: "The Pearl Experience",
    desc: (
      <>
        Experience the warmth and hospitality of Uganda, known as the "Pearl of Africa." From exploring its stunning landscapes to indulging in its rich cultural heritage, attending PAUDC KAMPALA 2024 promises unforgettable adventures and lasting memories.
      </>
    ),
    Icon: DesignSVG,
  },
  {
    title: "Inclusive Community",
    desc:
      "Join a diverse community of debaters, educators, and leaders from across Africa and beyond. PAUDC KAMPALA 2024 is committed to inclusivity, providing opportunities for all participants to learn, grow, and contribute to the continent's intellectual landscape.",
    Icon: BrandSVG,
  },
];

// features
export const featuresData = [
  {
    title: "Introduction and Background",
    desc:
      "The Pan African Universities Debating Championship (PAUDC) 2024 is set against the backdrop of Africa's evolving landscape, characterized by economic growth juxtaposed with persistent challenges of insecurity and inequality. Informed by UNESCO's insights, the event aims to address these issues by fostering critical dialogue and innovative solutions.",
    Icon: UsersSVG,
  },
  {
    title: "Theme: Science, Technology, and Innovation for Peacebuilding",
    desc:
      "With a theme centered on Science, Technology, and Innovation as tools for peacebuilding, PAUDC 2024 seeks to harness the potential of these disciplines to promote social equity, poverty alleviation, and access to knowledge. By leveraging Information and Communication Technologies (ICTs) and Indigenous Knowledge Systems, the championship aims to empower marginalized communities and foster inclusivity.",
    Icon: HelpSVG,
  },
  {
    title: "Vision for PAUDC",
    desc:
      "PAUDC serves as a premier platform for African youth to exchange knowledge, learn from each other, and influence policy-making. By embracing diversity and unity, the event aims to facilitate cultural integration and inter-university cooperation while advocating for the active involvement of young people in decision-making processes.",
    Icon: UsersSVG,
  },
  {
    title: "Style of Debates: British Parliamentary Format",
    desc:
      "Adopting the British Parliamentary debate format, PAUDC features four teams of two individuals each, engaging in structured debates on diverse topics. This format encourages critical thinking, effective communication, and collaborative problem-solving, reflecting the championship's commitment to nurturing future leaders and changemakers.",
    Icon: HelpSVG,
  },
  {
    title: "Participants and Expectations",
    desc:
      "PAUDC 2024 brings together universities from all over Africa, with participants including debaters, adjudicators, trainers, and policymakers. Expecting a turnout of over 650 individuals, the championship offers a platform for intense competition, formal training workshops, and interactive forums with key stakeholders.",
    Icon: UsersSVG,
  },
  {
    title: "Media Campaign and Outreach",
    desc:
      "Complementing the championship is a robust media campaign leveraging traditional and digital platforms. Through partnerships with radio, TV, and social media channels, PAUDC aims to raise awareness, amplify youth agendas, and foster meaningful dialogue on pressing issues facing the African continent",
    Icon: HelpSVG,
  },
];

// testimonials
export const testimonialData = [
  {
    text:
      "We're excited to attend PAUDC Kampala 2024 and be part of such an impactful event!",
    name: "Ojambo Shabil",
    occupation: "Olympia Debate Society",
    image: Screen1Img,
  },
  {
    text:
      "On Friday last week I met the President of Africa debating society to discuss how Kyambogo University will host the next Africa debate championship in December 2024. Kyambogo has been excelling in debate champions. The best debaters come from Engineering and Science. Amazing.",
    name: "Prof Eli Katunguka",
    occupation: "Vice Chancellor Kyambogo University",
    image: Screen2Img,
  },
  {
    text:
      "Looking forward to engaging in insightful debates and meeting fellow participants at PAUDC Kampala 2024!",
    name: "Jerry Vance Anguzu",
    occupation: "CEO, Everpesa Technologies",
    image: Screen3Img,
  },
  {
    text:
      "The theme for PAUDC Kampala 2024 is so timely and relevant. Can't wait to contribute to the discussions!",
    name: "Ayafa Tonye",
    occupation: "PAUDC Chair",
    image: Screen4Img,
  },
];

export const financialData = [
  {
    title: "DAY 1: Arrivals",
    Icon: CreditScore,

    content: (
      <p>
        <b>Sunday, 15th December 2024</b>
        <br />
        <br />
        Arrive at Entebbe International Airport, where you will be met by our
        representative and transferred to your hotel in Kampala. Check-in and
        relax after your journey. Enjoy a welcome dinner at the hotel and
        receive a briefing on the upcoming events.
        <br />
      </p>
    ),
  },
  {
    title: "DAY 2:", 
    Icon: CreditScore,
    content: (
      <p>
        <b>Monday, 16th December 2024</b>
        <br />
        <br />
        07:30 - 09:00  Breakfast at the hotel
        <br />
        09:00 - 10:00  Pre-Council Meeting
        <br />
        09:00 - 13:00  Training (Debate, Public Speech, Adjudication & Tabulation)
        <br />
        12:30 - 13:30  Lunch
        <br />
        14:00 - 15:30  Opening Ceremony
        <br />
        15:30 - 16:00  Briefing
        <br />
        16:00 - 17:00  Debate Round 1
        <br />
        17:00 - 17:30  Briefing
        <br />
        17:30 - 18:30  Public Speech Round 1 & 2
        <br />
        19:00 - 20:00  Dinner
        <br />
        21:00 - Late  Opening Night Live Band (Meet & Greet)
        <br />

      </p>
    ),
  },
  {
    title: "DAY 3:", 
    Icon: CreditScore,
    content: (
      <p>
        <b>Tuesday, 17th December 2024</b>
        <br />
        <br />
        07:30 - 09:00  Breakfast at the hotel
        <br />
        09:00 - 09:30  Briefing
        <br />
        09:30 - 10:30  Debate Round 2
        <br />
        10:30 - 11:00  Briefing
        <br />
        11:00 - 12:00  Debate Round 3
        <br />
        12:30 - 13:30  Lunch
        <br />
        13:30 - 14:00  Briefing
        <br />
        14:00 - 15:00  Debate Round 4
        <br />
        15:30 - 16:00  Briefing
        <br />
        16:00 - 17:00  Public Speech Round 3 & 4
        <br />
        19:00 - 20:00  Dinner
        <br />
        21:00 - Late  Cultural Night (Fashion, Music & Story Telling)
        <br />

      </p>
    ),
  },
  {
    title: "DAY 4:", 
    Icon: CreditScore,
    content: (
      <p>
        <b>Wednesday, 18th December 2024</b>
        <br />
        <br />
        07:30 - 09:00  Breakfast at the hotel
        <br />
        09:00 - 09:30  Briefing
        <br />
        09:30 - 10:30  Debate Round 5
        <br />
        10:30 - 11:00  Briefing
        <br />
        11:00 - 12:00  Debate Round 6
        <br />
        12:30 - 13:30  Lunch
        <br />
        13:30 - 14:00  Briefing
        <br />
        14:00 - 15:00  Debate Round 7
        <br />
        15:30 - 16:00  Briefing
        <br />
        16:00 - 17:00  Public Speech Round 5 & 6
        <br />
        19:00 - 20:00  Dinner
        <br />
        21:00 - Roast & Rhyme Evening (Nyama Choma, Music)
        <br />

      </p>
    ),
  },
  {
    title: "DAY 5:", 
    Icon: CreditScore,
    content: (
      <p>
        <b>Thursday, 19th December 2024</b>
        <br />
        <br />
        07:30 - 09:00  Breakfast at the hotel
        <br />
        09:00 - 09:30  Briefing
        <br />
        09:30 - 10:30  Debate Round 8
        <br />
        10:30 - 11:00  Briefing
        <br />
        11:00 - 12:00  Debate Round 9
        <br />
        12:30 - 13:30  Lunch
        <br />
        13:30 - 16:00  PAUDC Council Meeting
        <br />
        16:00 - 19:00  Sports Evening
        <br />
        19:00 - 20:00  Dinner
        <br />
        21:00 - 22:00  Announcing Breaking Teams
        <br />
        22:00 - Late  Break Night Party/ Yakka Night (UG Cocktails)
        <br />

      </p>
    ),
  },
  {
    title: "DAY 6:", 
    Icon: CreditScore,
    content: (
      <p>
        <b>Friday, 20th December 2024</b>
        <br />
        <br />
        07:30 - 08:30  Breakfast at the hotel
        <br />
        08:30 - 08:45  Briefing
        <br />
        08:45 - 09:45  Quarter-Finals of Public Speech
        <br />
        09:45 - 10:45  Debate Octo-Finals
        <br />
        11:00 - 19:00  The Pearl Tour
        <br />
        19:00 - 20:00  Dinner
        <br />
      </p>
    ),
  },
  {
    title: "DAY 7:", 
    Icon: CreditScore,
    content: (
      <p>
        <b>Saturday, 21st December 2024</b>
        <br />
        <br />
        07:30 - 08:30  Breakfast at the hotel
        <br />
        08:30 - 08:45  Briefing
        <br />
        08:45 - 09:45  Debate Quarter Finals
        <br />
        09:45 - 10:00  Briefing
        <br />
        10:00 - 11:00  Public Speech Semi-Finals
        <br />
        11:00 - 11:15  Briefing
        <br />
        11:15 - 12:45  Debate Semi-Finals
        <br />
        12:45 - 13:00  Announcing Finalists
        <br />
        12:30 - 13:30  Working Lunch
        <br />
        13:30 - 14:00  Briefing
        <br />
        14:00 - 15:30  Public Speech Finals
        <br />
        15:30 - 16:00  Briefing
        <br />
        16:00 - 17:00  Debate Finals
        <br />
        17:00 - 18:00  Awards & Closing Ceremony
        <br />
        19:00 - 20:00  Dinner
        <br />
        21:00 - Late  Farewell Party/ Afrika Night

      </p>
    ),
  },
  {
    title: "DAY 8: Departures",
    Icon: CreditScore,

    content: (
      <p>
        <b>Sunday, 22nd December 2024</b>
        <br />
        <br />
        Enjoy a leisurely breakfast at the hotel before checking out. Our
        representative will transfer you to Entebbe International Airport for
        your departure flight. Bid farewell to Uganda with cherished memories
        and unforgettable experiences.
        <br />
      </p>
    ),
  },
];

export const tradeFinanceData = [
  {
    title: "PAUDC Kampala 2024",
    Icon: CreditScore,
    content: (
      <p>
        <b>PAUDC Kampala 2024</b>
        <br />
        <br />
        The Pan-African Universities Debating Championship (PAUDC) is the
        largest continental debating tournament in Africa, bringing together
        debaters, judges, and observers from universities across the continent.
        The tournament provides a platform for intellectual exchange, critical
        thinking, and meaningful dialogue on pressing issues facing Africa and
        the world.
        <br />
      </p>
    ),
  },
];

// address Data
export const addressData = [
  {
    text: "Kyambogo University, Kyambogo Hill, P.O. Box 1 Kyambogo",
    icon: LocationOn,
  },
  {
    text: "Tel: +256 784 512 016",
    icon: LocalPhone,
  },
  {
    text: "info@olympiadebate.com",
    icon: Mail,
  },
];
